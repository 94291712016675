import React from "react";
import Flex from "app/components/Flex";
import { H1 } from "app/components/Typography";

const CommunityHeader = ({ challenge }) => {
  const { title, description = "" } = challenge;

  const customDescription = description.split("\n");

  return (
    <Flex
      flexDirection="column"
      mb={4}
      width="100%"
      pl={{ _: 3, xs: 3, md: 3, lg: 0 }}
      pr={{ _: 3, xs: 3, md: 3, lg: 0 }}
    >
      <H1 fontSize={{ _: "18px", sm: "25px" }} mb={3}>
        Join the {title}
      </H1>
      <Flex maxWidth={{ _: "751px", md: "650px" }} flexDirection="column">
        {customDescription.map(line => (
          <p>{line}</p>
        ))}
      </Flex>
    </Flex>
  );
};

export default CommunityHeader;
